//token单位
export function getTokenUnit(amount) {
  let formattedAmount
  // 转换为数值类型
  amount = Number(amount)
  if (amount >= 1000000) {
    // 金额大于等于1万时，转换为“万”为单位，保留两位小数
    formattedAmount = (amount / 1000000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + 'M'
  } else {
    formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return formattedAmount
}



