<template>
  <div class="header-box">
    <!-- 顶部 -->
    <div class="header-wrap" v-bind:style="fixed">
      <div class="header">
        <div class="text" @click="gohomePage">
          <img class="icon_logo" src="/img/home-logo.png" alt="" />
          <div class="register">
            注册即送<span class="money">{{ getTokenUnit(registerCash) }}</span
            >体验token
          </div>
          <img class="img" src="/img/home-redpacket.gif" alt="" />
        </div>
        <div class="left" @click="gohomePage">
          <img class="get-img" src="/img/new_home20.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTokenUnit } from "@/utils/util";
import { channelInfo, configInfo } from "@/api/customer";
import QRCode from "qrcodejs2";
export default {
  name: "Header-box",
  components: {
    QRCode,
  },
  data() {
    return {
      show: false,
      fixed: "",
      src1: "/img/home-logo.png",
      //代理商id
      cid: "",
      //体验价
      registerCash: "",
    };
  },
  mounted() {
    this.cid = this.$cookies.get("aiseo_channel_ext");
    console.log(this.cid, "--> this.cid");
    if (this.cid) {
      //代理商
      this.getChannelInfo();
    } else {
      //系统
      this.getSystemConfigInfo();
    }
  },
  methods: {
    getTokenUnit,
    //代理商信息
    async getChannelInfo() {
      let ret = await channelInfo({
        cid: this.cid,
      }).then(async (res) => {
        if (res.code == 20000) {
          this.channelData = res.data;
          this.systemData = await this.getConfigInfo();
          //代理商注册
          if (this.channelData.balance > this.channelData.register_cash) {
            this.registerCash =
              parseFloat(this.channelData.register_cash) +
              parseFloat(this.systemData.register_cash);
          } else {
            this.registerCash = this.systemData.register_cash;
          }
        }
      });
    },
    //后台配置信息
    async getConfigInfo() {
      let ret = await configInfo().then((res) => {
        if (res.code == 20000) {
          return res.data;
        }
      });
      return ret;
    },
    //系统注册金
    async getSystemConfigInfo() {
      let ret = await configInfo().then((res) => {
        if (res.code == 20000) {
          this.registerCash = parseFloat(res.data.register_cash);
        }
      });
    },
    //去h5页面
    gohomePage() {
      let ext = this.$cookies.get("aiseo_channel_ext");
      if (ext) {
        window.open("https://www.aiseo.net?ext=" + ext);
      } else {
        window.open("https://www.aiseo.net");
      }
    },
  },
};
</script>
